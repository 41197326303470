<script>
import Layout from "../../layouts/main";
import {
  studentClassMethods,
} from "@/state/helpers";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      Student:null
    };
  },
  created() {
    this.Student = JSON.parse(localStorage.getItem("user"));
    this.getStudentClass({ id:this.Student.id})
   
  },
  computed:{    
    studentclasses(){
      return   this.$store ? this.$store.state.studentsclass.StudentsClass : null;
    },
  },
  methods:{
    ...studentClassMethods,
    
  },
 
};
</script>

<template>
  <layout>
    <b-col >
      <b-row style="margin:0px;padding:0px;" v-for="(group) in studentclasses" :key="group.id" class="mt-4">
        <b-col>
          <b-card
        style="background-color:darkred;color:white;border-radius:15px;display: flex;padding:10px;margin:0px;" no-body
      >
       
                <b-col sm="12" md="12" style="display: flex;">
                  <div
                    style="background-color:white;border-radius:10px; color:black;height:80px;width:80px;max-width:80px;padding:17px;font-size:30px;display: flex;margin-right:20px;"
                  >
                  {{group.classsubject.substring(0,3)}}
                  </div>
                  <div>
                    <h5 style="color:white;">{{group.classtitle}}</h5>
                    <h6 style="color:white;"> {{group.classsubject}}</h6>
                    <h6 style="color:white;"> {{group.startat}}</h6>
                    <h6 style="color:white;"> {{group.schoolname}}</h6>
                  </div>
                </b-col>
      </b-card>
        </b-col>
   
    </b-row>
    </b-col>
  </layout>
</template>
